import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult, ConfirmDialogOptions } from '../dialog.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ConfirmComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) public args: ConfirmDialogOptions
  ) { }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close('OK');
  }

  cancel() {
    this.dialogRef.close('Cancel');
  }
}
