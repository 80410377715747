import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  file: File | null = null;
  isValid = true;
  invalidMsg: string;

  @Output() imported = new EventEmitter<string[]>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList ) {
    console.log(event);
    const file = event && event.item(0);
    this.file = file;

    const extension = file.name.split('.')[1].toLowerCase();
    this.validateExtension(extension);
    if (this.isValid) {
      console.log(this.file);
      this.readFile(file);
    }
  }

  constructor() { }

  ngOnInit() {
  }

  validateExtension(ext: string) {
    if (ext === 'xlsx' || ext === 'xlx') {
      this.isValid = true;
      this.invalidMsg = '';
    } else {
      this.isValid = false;
      this.invalidMsg = '檔案格式不正確';
    }
  }

  readFile(file: Blob) {
    // A File object is a specific kind of a Blob, and can be used in any context that a Blob can. In particular, FileReader
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = (e) => {
      const workbook = XLSX.read(reader.result, { type: 'binary' });
      const { Sheets, SheetNames } = workbook;
      const sheet = Sheets[SheetNames[0]];
      const dataRow = XLSX.utils.sheet_to_json(sheet) as any;
      this.imported.emit(dataRow.map(data => data.StudentNumber));
    };

  }

}
