import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { SetHandsetMode } from './app.action';

export interface AppStateRec {
  handset: boolean;
}

@State({
  name: 'app',
  defaults: { handset: false } as AppStateRec
})
@Injectable()
export class AppState {

  constructor() {}

  /** 設定目前是否為手機模式 */
  @Action(SetHandsetMode)
  setHandsetMode(ctx: StateContext<AppStateRec>, action: SetHandsetMode) {
    ctx.patchState({ handset: action.handset });
  }
}
