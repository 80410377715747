import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilityService } from './core/utility.service';


@Injectable({
  providedIn: 'root'
})
export class BackendApiHttpInterceptorService implements HttpInterceptor {

  constructor(
    private utilitySrv: UtilityService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.utilitySrv.exitSystem();
        } else {
          return throwError(error);
        }
      })
    );
  }

}
