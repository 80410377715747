import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetHandsetMode } from './app.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isHandsetMode$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store) {
  }

  async ngOnInit() {

    this.isHandsetMode$ = this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).pipe(
      map(match => match.matches)
    );

    this.isHandsetMode$.subscribe(result => {
      console.log(`HandsetMode: ${result}`);
      this.store.dispatch(new SetHandsetMode(result));
    });
  }
}
