import { Pipe, PipeTransform } from '@angular/core';

/** 過濾 HTML 內容 */
@Pipe({name: 'filterHtml'})
export class FilterHtmlPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    // 過濾標籤語言
    return value.replace(/(<([^>]+)>)/ig, '');
  }

}
