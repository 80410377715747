import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error401Component } from './error401/error401.component';
import { Error403Component } from './error403/error403.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'message', loadChildren: () => import('./message/message.module').then(m => m.MessageModule) },
  { path: 'message_admin', loadChildren: () => import('./message/message-admin/message-admin.module').then(m => m.MessageAdminModule) },
  { path: 'error_401', component: Error401Component },
  { path: 'error_403', component: Error403Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
