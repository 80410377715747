import { RoleRec } from './../data/role';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private http: HttpClient
  ) { }

  /** 登出或關閉 */
  public exitSystem() {
    if (this.isApp()) {
      window.location.replace('https://cooc.tp.edu.tw/app/close');
    } else {
      window.location.replace('/auth/signout');
    }
  }

  /** 判斷是否為 webview */
  public isApp(){
    //@ts-ignore
    return !!window.ReactNativeWebView;
  }
}
