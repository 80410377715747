import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmComponent } from './confirm/confirm.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
// pipe
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { FilterHtmlPipe } from './pipe/filter-html.pipe';
// angular material
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    ConfirmComponent,
    ErrorDialogComponent,
    SafeHtmlPipe,
    FilterHtmlPipe,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatListModule,
    FlexLayoutModule,
    MatSortModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatListModule,
    FlexLayoutModule,
    MatSortModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    SafeHtmlPipe,
    FilterHtmlPipe,
    FileUploadComponent
  ],
  entryComponents: [
    ConfirmComponent,
    ErrorDialogComponent
  ]
})
export class SharedModule { }
