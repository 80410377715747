import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UtilityService } from '../core/utility.service';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss']
})
export class Error401Component implements OnInit {

  @ViewChild(TemplateRef, { static: true }) dialogTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private utilitySrv: UtilityService,
  ) {}

  ngOnInit() {
    this.dialog.open(this.dialogTemplate, {
      maxWidth: 800, width: '80vw'
    });
  }

  close() {
    this.dialog.closeAll();
    this.utilitySrv.exitSystem();
  }
}
