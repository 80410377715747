import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class CoreModule {
  constructor(@SkipSelf() @Optional() parent: CoreModule) {
    if (parent) {
      throw new Error("重覆 import core module.");
    }
  }
}
