import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from './welcome/welcome.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxsModule } from '@ngxs/store';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Error401Component } from './error401/error401.component';
import { Error403Component } from './error403/error403.component';
import { BackendApiHttpInterceptorService } from './backend-api-http-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppState } from './app.state';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LoadingScreenComponent,
    Error401Component,
    Error403Component,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    NgxsModule.forRoot([AppState]),
    ModalModule.forRoot(),
  ],
  providers: [
    BackendApiHttpInterceptorService, {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendApiHttpInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
