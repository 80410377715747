import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  checking = true;
  done = false;

  response: any;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {

    // http://localhost:3001/api/common/status_check

    try {
      this.response = await this.http.get('/api/common/status_check').toPromise();
      this.done = true;
    } catch(err) {
      this.response = err;
      this.done = false;
    }
    this.checking = false;
  }

}
