import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UtilityService } from '../core/utility.service';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss']
})
export class Error403Component implements OnInit {

  @ViewChild(TemplateRef, { static: true }) dialogTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public utilitySrv: UtilityService,
  ) {}

  ngOnInit() {
    this.dialog.open(this.dialogTemplate, {
      maxWidth: 800, width: '80vw'
    });
  }

  close() {
    this.dialog.closeAll();
    this.utilitySrv.exitSystem();
  }
}
